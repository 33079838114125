import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import CatList from '../catList/CatList'

const NewsGridStyles = styled.div`
  ol {
    list-style: none;
    padding-left: 10px;
  }
  .recent,
  .other {
    /* padding-top: 10px; */
    /* padding-left: 30px; */
  }

  .recent {
    margin-bottom: 30px;
  }

  h2.section-title {
    font-weight: bold;
  }
  @media all and (min-width: 1136px) {
    ol {
      padding-left: 30px;
    }
  }
`

const NewsStyles = styled.li`
  margin-top: 10px;
  h5 {
    margin: 0;
    font-style: italic;
    margin-bottom: 8px;
  }

  p {
    padding: 0;
    margin: 0;
    /* margin-bottom: 10px; */
    font-size: 1.5rem;
    font-style: italic;
  }

  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
  }

  margin-bottom: 20px;
`

const SimpleNewsStyles = styled.li`
  padding-bottom: 10px;
  margin-top: 10px;

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-style: italic;
  }

  .cat {
    margin-top: 5px;
  }
`

const genDateElement = item => {
  const createdDate = new Date(item._createdAt)
  const updatedDate = new Date(item._updatedAt).toLocaleDateString()
  let string
  if (createdDate === updatedDate) {
    string = `(Published: ${createdDate})`
  } else if (updatedDate) {
    string = `(Updated: ${updatedDate})`
  }

  return <p>{string}</p>
}

const wrapInternal = (el, content) => (
  <Link to={content.slug.current} rel="canonical">
    {el}
  </Link>
)

const wrapExternal = (el, content) => (
  <a href={content.url} target="_blank" rel="noopener noreferrer">
    {el}
  </a>
)

function SingleNewsItem({ news }) {
  const dateElement = genDateElement(news)
  const content = news.newsContent[0]

  let newsInfo = (
    <>
      <div className="title">
        <h2>{news.title} - </h2>
        {dateElement}
      </div>
      <h5>{news.headline}</h5>
    </>
  )

  if (content._type === 'internalNews') {
    newsInfo = wrapInternal(newsInfo, news)
  } else {
    newsInfo = wrapExternal(newsInfo, content)
  }

  return (
    <NewsStyles>
      {newsInfo}
      <CatList className="cat" categories={news.categories} keyName={news.id} />
    </NewsStyles>
  )
}

function SimpleNewsItem({ news }) {
  const dateElement = genDateElement(news)

  const content = news.newsContent[0]

  let newsInfo = (
    <>
      <h4>{news.title}</h4>
      {dateElement}
    </>
  )

  if (content._type === 'internalNews') {
    newsInfo = wrapInternal(newsInfo, news)
  } else {
    newsInfo = wrapExternal(newsInfo, content)
  }

  return (
    <SimpleNewsStyles>
      <div className="content">
        {newsInfo}
        <CatList
          className="cat"
          categories={news.categories}
          keyName={news.id}
        />
      </div>
    </SimpleNewsStyles>
  )
}

export default function NewsList({ news }) {
  const sliceRange = 4
  const recentNews = news.slice(0, sliceRange)
  const otherNews = news.slice(sliceRange)
  let recentElements = <></>
  let otherElements = <></>

  if (recentNews.length) {
    recentElements = (
      <>
        <h2 className="section-title">Recent News:</h2>
        <ol className="recent">
          {recentNews.map(newsItem => (
            <SingleNewsItem key={newsItem.id} news={newsItem} />
          ))}
        </ol>
      </>
    )
  }

  if (otherNews.length) {
    otherElements = (
      <>
        <h2 className="section-title">Other News:</h2>
        <ol className="other">
          {otherNews.map(newsItem => (
            <SimpleNewsItem key={newsItem.id} news={newsItem} />
          ))}
        </ol>
      </>
    )
  }

  return (
    <NewsGridStyles>
      {recentElements}
      {otherElements}
    </NewsGridStyles>
  )
}
