import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

export const CatListStyles = styled.ul.attrs(props => {
  const { stats } = props

  return {
    align: stats.align,
  }
})`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align};
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 1.2rem;

  li {
    a {
      border-radius: 5px;
      padding: 3px 5px;
    }
  }
`

export default function CatList({ categories, keyName, align = 'left' }) {
  return (
    <CatListStyles stats={{ align }}>
      {categories.map((cat, i) => (
        <li key={`catList${keyName}${i}`}>
          <Link
            style={{
              color: cat.invert ? '#ffffff' : '',
              backgroundColor: cat.color.hex,
            }}
            to={`?cat=${cat.name}`}
          >
            {cat.name}
          </Link>
        </li>
      ))}
    </CatListStyles>
  )
}
