import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { getSearchParams } from '../utils/utils'

const CategoryNavStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;
  font-size: 80%;
  width: 100%;

  a {
    padding: 4px 8px;
    display: grid;
    grid-template-columns: auto 0.5fr;
    grid-gap: 0 0.5rem;
    align-items: center;
    text-decoration: none;
    font-size: clamp(1.5rem, 1.1vw, 2.5rem);

    border-radius: 5px;

    &.active {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 3px #777777;
    }
  }

  @media all and (min-width: 700px) {
    justify-content: flex-end;
  }
`

const createCategoryArr = itemsArr => {
  const catObj = itemsArr.reduce((cObj, listItem) => {
    const { categories } = listItem

    categories.forEach(cat => {
      if (!cObj[cat.name]) {
        cObj[cat.name] = {
          name: cat.name,
          bgColor: cat.color.hex,
          color: cat.invert ? '#ffffff' : '',
          count: 1,
        }
      } else {
        cObj[cat.name].count += 1
      }
    })

    return cObj
  }, {})

  const catArr = Object.keys(catObj)
    .sort()
    .map(name => catObj[name])

  return catArr
}

const modifyColor = (color, lighten) => {
  if (!lighten) return color

  const r = parseInt(color.substring(1, 3), 16)
  const g = parseInt(color.substring(3, 5), 16)
  const b = parseInt(color.substring(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, 0.7)`
}

export default function CategoryNavList({ items, baseUrl, location }) {
  const catArr = createCategoryArr(items)
  const curCat = _.get(getSearchParams({ location }), ['cat'], '')

  return (
    <CategoryNavStyles>
      <h3>Filters:</h3>
      <Link
        to={baseUrl}
        className={curCat === '' ? 'active' : ''}
        style={{
          backgroundColor: modifyColor('#555555', curCat === ''),
          color: '#ffffff',
        }}
      >
        <span className="name">All</span>
        <span className="count">({items.length})</span>
      </Link>
      {catArr.map(c => (
        <Link
          key={`${c.name}-nav`}
          className={curCat === c.name ? 'active' : ''}
          to={`${baseUrl}?cat=${c.name}`}
          style={{
            backgroundColor: modifyColor(c.bgColor, c.name === curCat),
            color: c.color,
          }}
        >
          <span className="name">{c.name}</span>
          <span className="count">({c.count})</span>
        </Link>
      ))}
    </CategoryNavStyles>
  )
}
