import queryString from 'query-string'
import _ from 'lodash'

export const getSearchParams = ({ location }) => {
  const { search } = location

  if (search) {
    return queryString.parse(search)
  }

  return null
}

export const filterForCat = (list, location) => {
  const query = getSearchParams({ location })
  if (query && query.cat) {
    return list.filter(item => {
      const categoryNames = item.categories.map(c => c.name)
      return categoryNames.includes(query.cat)
    })
  }

  return list
}

export const filterChoruses = (list, location) => {
  const query = getSearchParams({ location })

  if (query && query.location) {
    return [list.find(chorus => chorus.title === query.location)]
  }

  return []
}

export const filterEventsByQuery = ({ list, location }) => {
  const eventName = _.get(getSearchParams({ location }), ['event'], null)

  if (!eventName) return list

  const foundItem = list.find(item => item.slug.current === eventName)

  if (foundItem) {
    return [foundItem]
  }

  return list
}

export const filterList = ({
  list,
  location,
  propToCheck,
  filterQueryName,
  defaultTitle,
}) => {
  const query = getSearchParams({ location })

  const value = _.get(query, [filterQueryName], null)

  if (value) {
    const filteredList = list.filter(item => item[propToCheck] === value)

    return { list: filteredList, query: value }
  }

  return { list, query: defaultTitle }
}

export const replaceAllSearchParams = (location, replacements) => {
  const params = getSearchParams({ location }) || {}
  const allParams = Object.assign(params, replacements)

  return Object.entries(allParams)
    .map(arr => `${arr[0]}=${arr[1]}`)
    .join('&')
}
