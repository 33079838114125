import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import NewsList from '../components/lists/NewsList'
import CategoryNavList from '../templates/CategoryNavList'
import { filterForCat } from '../utils/utils'

const NewsStyles = styled.div`
  .cat-nav {
    margin-bottom: 30px;
  }
`

export default function NewsPage({ data, location }) {
  const news = data.news.nodes
  const filteredNews = filterForCat(news, location)

  return (
    <NewsStyles className="content-width">
      <h1 className="title">Pioneer District News</h1>
      <CategoryNavList items={news} location={location} baseUrl="/news" />
      <NewsList news={filteredNews}>News Page</NewsList>
    </NewsStyles>
  )
}

export const query = graphql`
  query NewsQuery {
    news: allSanityNews(
      filter: { publishDate: { lte: "now" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id
        title
        headline
        slug {
          current
        }
        categories {
          name
          invert
          color {
            hex
          }
        }
        newsContent: _rawNewsContent
        _createdAt
        _updatedAt
      }
    }
  }
`
